import { Node, Edge } from "reactflow";

export type Member = {
  id: string;
  name: string;
  role: string;
  nationality?: string;
  email?: string;
};

export type Members = Map<string, Member>;

type Item = {
  id: string;
  name: string;
  description?: string;
  status?: Status;
};

export type Status = "RED" | "YELLOW" | "BLUE" | "GREEN";

export const StatusColors = {
  RED: "#ef5350",
  YELLOW: "#ff9800",
  BLUE: "#42a5f5",
  GREEN: "#4caf50",
  GRAY: "#808080",
};

export type Task = Item & {
  startDate?: string;
  endDate?: string;
  assignee?: Member;
};

export type Tasks = Map<string, Task>;

export type Milestone = Item & {
  startDate?: string;
  endDate?: string;
  tasks?: Tasks;
  flow?: {
    nodes: Node<any, string | undefined>[];
    edges: Edge<any>[];
  };
};

export type Milestones = Map<string, Milestone>;

export type Phase = Item & {
  milestones?: Milestones;
};

export type Phases = Map<string, Phase>;

export type Pod = Item & {
  startDate?: string;
  endDate?: string;
  phases?: Phases;
  members?: Members;
};

export type Pods = Map<string, Pod>;
