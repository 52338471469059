import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Layout, Space, Menu, Button } from "antd";
import { useStore } from "hooks/store";
import type { Pods, Phases, Milestones, Tasks } from "models/pods";
import { usePathKeys } from "hooks/dashboard";
import styled from "@emotion/styled";
import { blue } from "@ant-design/colors";
import { LuListTree, LuList } from "react-icons/lu";
import {
  MdOutlineSupervisedUserCircle,
  MdOutlineCalendarMonth,
  MdOutlineDirections,
} from "react-icons/md";
import { PiCheckSquareBold } from "react-icons/pi";

const Root = styled(Layout.Sider)({
  borderRight: "1px solid #ddd",
});

const Title = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px 28px",
});

const TitleText = styled.span({
  fontSize: "1rem",
  fontWeight: 500,
  textTransform: "uppercase",
});

const TitleButton = styled(Button)({
  padding: "0 3px !important",
  fontSize: "1rem !important",
});

const MainMenu = styled(Menu)({
  color: "#414244",
  "& a": {
    color: "inherit",
  },
  "& .ant-menu-title-content": {
    color: "#202123",
  },
  "& .ant-menu-submenu-selected:not(:has(.ant-menu-item-selected)):not(:has(.ant-menu-submenu-selected))":
    {
      backgroundColor: "#f0f0f0",
    },
  "& .ant-menu-item, & .ant-menu-submenu-title": {
    borderLeft: "3px solid transparent",
  },
  "& .ant-menu-item-selected, & .ant-menu-submenu-selected:not(:has(.ant-menu-item-selected)):not(:has(.ant-menu-submenu-selected)) > .ant-menu-submenu-title":
    {
      borderLeftColor: blue.primary,
    },
});

const stopPropagation = (e: React.MouseEvent<HTMLAnchorElement>) =>
  e.stopPropagation();

const createTasks = (tasks: Tasks | undefined, base: string) =>
  tasks
    ? Array.from(tasks.entries()).map(([key, task]) => ({
        key,
        label: (
          <Link
            to={`${base}/${task.id}`}
            onClick={stopPropagation}
            title={task.name}
          >
            {task.name}
          </Link>
        ),
        icon: <PiCheckSquareBold size={24} />,
      }))
    : undefined;

const createMilestones = (milestones: Milestones | undefined, base: string) =>
  milestones
    ? Array.from(milestones.entries()).map(([key, milestone]) => ({
        key,
        label: (
          <Link
            to={`${base}/${milestone.id}`}
            onClick={stopPropagation}
            title={milestone.name}
          >
            {milestone.name}
          </Link>
        ),
        icon: <MdOutlineDirections size={24} />,
        children: createTasks(milestone.tasks, `${base}/${milestone.id}`),
      }))
    : undefined;

const createPhases = (phases: Phases | undefined, base: string) =>
  phases
    ? Array.from(phases.entries()).map(([key, phase]) => ({
        key,
        label: (
          <Link
            to={`${base}/${phase.id}`}
            onClick={stopPropagation}
            title={phase.name}
          >
            {phase.name}
          </Link>
        ),
        icon: <MdOutlineCalendarMonth size={24} />,
        children: createMilestones(phase.milestones, `${base}/${phase.id}`),
      }))
    : undefined;

const createPods = (pods: Pods | undefined, base: string) =>
  pods
    ? Array.from(pods.entries()).map(([key, pod]) => ({
        key,
        label: (
          <Link
            to={`${base}/${pod.id}`}
            onClick={stopPropagation}
            title={pod.name}
          >
            {pod.name}
          </Link>
        ),
        icon: <MdOutlineSupervisedUserCircle size={24} />,
        children: createPhases(pod.phases, `${base}/${pod.id}`),
      }))
    : undefined;

const Sidebar = () => {
  const { value: pods } = useStore<Pods>("pods");

  const menuData = useMemo(() => createPods(pods, "/dashboard"), [pods]);

  const pathKeys = usePathKeys();

  const selectedKeys = useMemo(
    () =>
      pathKeys && pathKeys.length > 0
        ? [pathKeys[pathKeys.length - 1]]
        : undefined,
    [pathKeys]
  );

  const [openKeys, setOpenKeys] = useState<string[]>();

  useEffect(() => {
    if (!openKeys) {
      setOpenKeys(pathKeys?.slice(0, -1));
    }
  }, [openKeys, pathKeys]);

  const openSubmenu = (openKeys: string[]) => setOpenKeys(openKeys);

  const expandSubmenus = () => {
    const keys: string[] = [];
    if (pods) {
      pods.forEach((pod, podKey) => {
        if (pod.phases) {
          keys.push(podKey);
          pod.phases.forEach((phase, phaseKey) => {
            if (phase.milestones) {
              keys.push(phaseKey);
              phase.milestones.forEach((milestone, milestoneKey) => {
                if (milestone.tasks) {
                  keys.push(milestoneKey);
                }
              });
            }
          });
        }
      });
    }
    setOpenKeys(keys);
  };

  const collapseSubmenus = () => setOpenKeys([]);

  return (
    <Root width={320}>
      <Title>
        <TitleText>Dashboard</TitleText>
        <Space size={4}>
          <TitleButton size="small" title="Expand All" onClick={expandSubmenus}>
            <LuListTree />
          </TitleButton>
          <TitleButton
            size="small"
            title="Collapse All"
            onClick={collapseSubmenus}
          >
            <LuList />
          </TitleButton>
        </Space>
      </Title>
      <MainMenu
        mode="inline"
        items={menuData}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={openSubmenu}
      />
    </Root>
  );
};

export default Sidebar;
