import { Flex, Button } from "antd";
import styled from "@emotion/styled";
import { MdSettings, MdAccountCircle } from "react-icons/md";

const IconButton = styled(Button)({
  color: "#f0f0f0",
});

const Toolbar = () => (
  <Flex align="center" gap={12}>
    <IconButton
      type="link"
      shape="circle"
      size="small"
      icon={<MdSettings size={22} />}
      title="Settings"
    />
    <IconButton
      type="link"
      shape="circle"
      size="small"
      icon={<MdAccountCircle size={22} />}
      title="Account"
    />
  </Flex>
);

export default Toolbar;
