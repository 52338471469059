import { Link } from "react-router-dom";
import { Layout } from "antd";
import styled from "@emotion/styled";
import { BsTwitter, BsDiscord, BsYoutube, BsLinkedin } from "react-icons/bs";

const Root = styled(Layout.Footer)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderTop: "1px solid #ddd",
  color: "#666",
});

const ListContainer = styled.ul({
  display: "inline-flex",
  margin: 0,
  padding: 0,
});

const TextListContainer = styled(ListContainer)({
  gap: 24,
});

const IconListContainer = styled(ListContainer)({
  gap: 16,
});

const ListItem = styled.li({
  listStyleType: "none",
});

const TextLink = styled(Link)({
  fontSize: "0.8125rem",
  color: "#666",
  "&:hover": {
    color: "#666",
    textDecorationLine: "underline",
  },
});

const IconLink = styled(Link)({
  display: "flex",
  color: "#666",
});

const year = new Date().getFullYear();

const Footer = () => (
  <Root>
    <TextListContainer>
      <ListItem>
        <TextLink to="#">Privacy</TextLink>
      </ListItem>
      <ListItem>
        <TextLink to="#">Terms</TextLink>
      </ListItem>
      <ListItem>
        <TextLink to="#">About Us</TextLink>
      </ListItem>
      <ListItem>&copy; {year} Synergy</ListItem>
    </TextListContainer>
    <IconListContainer>
      <ListItem>
        <IconLink to="#">
          <BsTwitter size={18} />
        </IconLink>
      </ListItem>
      <ListItem>
        <IconLink to="#">
          <BsDiscord size={18} />
        </IconLink>
      </ListItem>
      <ListItem>
        <IconLink to="#">
          <BsYoutube size={18} />
        </IconLink>
      </ListItem>
      <ListItem>
        <IconLink to="#">
          <BsLinkedin size={18} />
        </IconLink>
      </ListItem>
    </IconListContainer>
  </Root>
);

export default Footer;
