import {
  createClient as createDeliverClient,
  type ContentfulClientApi,
} from "contentful";
import { createClient as createManagementClient } from "contentful-management";

const baseURL = "https://app.contentful.com";
const spaceURL = `${baseURL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}`;
const environmentURL = `${spaceURL}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}`;
export const entriesURL = `${environmentURL}/entries`;

let deliverClient: ContentfulClientApi<undefined>;

const getDeliverClient = () => {
  if (!deliverClient) {
    deliverClient = createDeliverClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE!,
      accessToken: process.env.REACT_APP_CONTENTFUL_DELIVER_TOKEN!,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT!,
    });
  }
  return deliverClient;
};

export const getDeliverEntries = () => {
  const client = getDeliverClient();
  return client.getEntries({ include: 4 });
};

let managementClient: any;

const getManagementClient = () => {
  if (!managementClient) {
    managementClient = createManagementClient({
      accessToken: process.env.REACT_APP_CONTENTFUL_MANAGEMENT_TOKEN!,
    });
  }
  return managementClient;
};

export const getManagementEntries = async () => {
  const client = getManagementClient();
  const space = await client.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE!);
  const environment = await space.getEnvironment(
    process.env.REACT_APP_CONTENTFUL_ENVIRONMENT!
  );
  return await environment.getEntries({ include: 4 });
};

export const saveTaskFlow = async (id: string, flow: any) => {
  const client = getManagementClient();
  const space = await client.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE!);
  const environment = await space.getEnvironment(
    process.env.REACT_APP_CONTENTFUL_ENVIRONMENT!
  );
  const entry = await environment.getEntry(id);
  entry.fields.flow = { "en-US": flow };
  entry.update();
};
