import Bootstrap from "views/bootstrap";
import { StoreProvider } from "hooks/store";

const App = () => (
  <StoreProvider>
    <Bootstrap />
  </StoreProvider>
);

export default App;
