import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "hooks/store";
import type { Pods } from "models/pods";

const childNames = ["phases", "milestones", "tasks"];

const getPathKeys = (pathname: string, pods?: Pods) => {
  if (pods) {
    const ids = pathname.split("/").filter(Boolean);
    if (ids.length > 0) {
      if (ids[0] === "dashboard") {
        ids.shift();
      } else {
        return undefined;
      }
    }
    if (ids.length === 0) {
      ids.push(pods.values().next().value.id);
    }

    const pathKeys = [];
    let items: Map<string, any> = pods;
    for (let index = 0; index < ids.length; index++) {
      const result = Array.from(items.entries()).find(
        ([, item]) => item.id === ids[index]
      );
      if (!result) {
        return undefined;
      }

      const [key, item] = result;
      pathKeys.push(key);
      items = item[childNames[index]];
      if (!items) {
        break;
      }
    }
    return pathKeys;
  }
};

export const usePathKeys = () => {
  const { pathname } = useLocation();
  const { value: pods } = useStore<Pods>("pods");

  const [pathKeys, setPathKeys] = useState<string[]>();

  useEffect(() => {
    setPathKeys(getPathKeys(pathname, pods));
  }, [pathname, pods]);

  return pathKeys;
};
