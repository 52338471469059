import { ConfigProvider, Layout } from "antd";
import Header from "components/header";
import Toolbar from "views/home/toolbar";
import Sidebar from "views/home/sidebar";
import Footer from "components/footer";
import Dashboard from "views/home/dashboard";
import styled from "@emotion/styled";

const Root = styled(Layout)({
  minHeight: "100vh",
});

const theme = {
  token: {
    borderRadius: 4,
  },
  components: {
    Layout: {
      bodyBg: "white",
      headerHeight: 48,
      headerPadding: "0 48px",
      headerBg: "#202123",
      siderBg: "white",
      footerPadding: "12px 48px",
      footerBg: "#f0f0f0",
    },
    Menu: {
      activeBarBorderWidth: 0,
      itemHeight: 40,
      itemMarginBlock: 0,
      itemMarginInline: 0,
      itemBorderRadius: 0,
      itemActiveBg: "#f0f0f0",
      itemSelectedColor: "inherit",
      itemSelectedBg: "#f0f0f0",
      subMenuItemBg: "white",
    },
    Breadcrumb: {
      lastItemColor: "#414244",
    },
  },
};

const Home = () => (
  <ConfigProvider theme={theme}>
    <Root>
      <Header>
        <Toolbar />
      </Header>
      <Layout hasSider>
        <Sidebar />
        <Dashboard />
      </Layout>
      <Footer />
    </Root>
  </ConfigProvider>
);

export default Home;
