import { useState, useEffect } from "react";
import slugify from "slugify";
import type { Entry } from "contentful";
import type { Pods, Status } from "models/pods";
import { getDeliverEntries as getEntries } from "services/contentful";

const compareEntry = (f1: any, f2: any) => {
  const id1 = f1.id as number | undefined;
  const id2 = f2.id as number | undefined;
  return id1 !== undefined && id2 !== undefined
    ? id1 - id2
    : id1 !== undefined && id2 === undefined
    ? -1
    : id1 === undefined && id2 !== undefined
    ? 1
    : 0;
};

const createId = (name: any) =>
  slugify(name as string, {
    lower: true,
    strict: true,
  });

const createItems = (entries: any[]) =>
  entries
    .filter((entry: Entry) =>
      ["pod", "phase", "milestone", "task", "member"].includes(
        entry.sys.contentType.sys.id
      )
    )
    .reduce((result: Record<string, any>, entry: Entry) => {
      result[entry.sys.id] = entry.fields;
      return result;
    }, {});

const createMembers = (members: any, items: Record<string, any>) =>
  members
    ? new Map(
        (members as []).map((entry: Entry) => {
          const member = items[entry.sys.id];
          return [
            entry.sys.id,
            {
              id: createId(member.name),
              name: member.name as string,
              role: member.role as string,
              nationality: member.nationality as string | undefined,
              email: member.email as string | undefined,
            },
          ];
        })
      )
    : undefined;

const createTasks = (tasks: any, items: Record<string, any>) =>
  tasks
    ? new Map(
        (tasks as [])
          .sort((e1: Entry, e2: Entry) => compareEntry(e1.fields, e2.fields))
          .map((entry: Entry) => {
            const task = items[entry.sys.id];
            return [
              entry.sys.id,
              {
                id: createId(task.name),
                name: task.name as string,
                description: task.description as string | undefined,
                startDate: task.startDate as string | undefined,
                endDate: task.endDate as string | undefined,
                status: task.status as Status | undefined,
                assignee: task.assignee
                  ? items[task.assignee.sys.id]
                  : undefined,
              },
            ];
          })
      )
    : undefined;

const createMilestones = (
  milestones: any,
  id: string,
  items: Record<string, any>
) =>
  new Map(
    (milestones as any[])
      .filter((milestone) => items[milestone.sys.id].phase.sys.id === id)
      .sort((e1: Entry, e2: Entry) => compareEntry(e1.fields, e2.fields))
      .map((entry: Entry) => {
        const milestone = items[entry.sys.id];
        return [
          entry.sys.id,
          {
            id: createId(milestone.name),
            name: milestone.name as string,
            description: milestone.description as string | undefined,
            startDate: milestone.startDate as string | undefined,
            endDate: milestone.endDate as string | undefined,
            status: milestone.status as Status | undefined,
            tasks: createTasks(milestone.tasks, items),
          },
        ];
      })
  );

const createPhases = (milestones: any, items: Record<string, any>) =>
  milestones
    ? new Map(
        Array.from(
          new Set(
            (milestones as any[]).map(
              (milestone) => items[milestone.sys.id].phase.sys.id
            )
          )
        )
          .sort((id1, id2) => compareEntry(items[id1], items[id2]))
          .map((id) => {
            const phase = items[id];
            return [
              id,
              {
                id: createId(phase.name),
                name: phase.name as string,
                description: phase.description as string | undefined,
                status: phase.status as Status | undefined,
                milestones: createMilestones(milestones, id, items),
              },
            ];
          })
      )
    : undefined;

const createPods = (entries: any[]) => {
  const items = createItems(entries);
  return new Map(
    entries
      .filter((entry: Entry) => entry.sys.contentType.sys.id === "pod")
      .sort((e1: Entry, e2: Entry) => compareEntry(e1.fields, e2.fields))
      .map((entry: Entry) => {
        const pod = entry.fields;
        return [
          entry.sys.id,
          {
            id: createId(pod.name),
            name: pod.name as string,
            description: pod.description as string | undefined,
            startDate: pod.startDate as string | undefined,
            endDate: pod.endDate as string | undefined,
            status: pod.status as Status | undefined,
            phases: createPhases(pod.milestones, items),
            members: createMembers(pod.members, items),
          },
        ];
      })
  );
};

export const usePods = () => {
  const [pods, setPods] = useState<Pods>();

  useEffect(() => {
    (async () => {
      const entries = await getEntries();
      const pods = createPods(entries.items);
      setPods(pods);
    })();
  }, []);

  return pods;
};
