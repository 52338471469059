import { ReactFlowProvider } from "reactflow";
import Flow from "views/home/flow";
import styled from "@emotion/styled";

const TaskFlow = styled.div({
  height: "75vh",
  marginTop: 16,
  border: "1px solid #ddd",
});

const Milestone = () => (
  <>
    <h2>Task Flow</h2>
    <ReactFlowProvider>
      <TaskFlow>
        <Flow />
      </TaskFlow>
    </ReactFlowProvider>
  </>
);

export default Milestone;
