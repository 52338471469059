import { ConfigProvider, Layout, Result, Button } from "antd";
import Header from "components/header";
import Footer from "components/footer";
import styled from "@emotion/styled";

const Root = styled(Layout)({
  minHeight: "100vh",
});

const Content = styled(Layout.Content)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const theme = {
  token: {
    borderRadius: 4,
  },
  components: {
    Layout: {
      bodyBg: "white",
      headerHeight: 48,
      headerPadding: "0 48px",
      headerBg: "#202123",
      footerPadding: "12px 48px",
      footerBg: "#f0f0f0",
    },
  },
};

const Error = () => (
  <ConfigProvider theme={theme}>
    <Root>
      <Header />
      <Content>
        <Result
          status="404"
          title="PAGE NOT FOUND"
          subTitle="Oops! The page your were looking for doesn't exist."
          extra={
            <Button type="primary" href="/">
              Back to Home
            </Button>
          }
        />
      </Content>
      <Footer />
    </Root>
  </ConfigProvider>
);

export default Error;
