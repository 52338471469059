import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { notification } from "antd";
import Home from "views/home";
import Error from "views/error";
import { useStore } from "hooks/store";
import { useSystem } from "hooks/system";
import type { System } from "models/system";
import { usePods } from "hooks/pods";
import type { Pods } from "models/pods";
import { Global } from "@emotion/react";
import "antd/dist/reset.css";
import "reactflow/dist/style.css";

const styles = {
  body: {
    maxWidth: 1920,
    margin: "0 auto",
    fontSize: "0.875rem",
  },
  "::-webkit-scrollbar": {
    width: 12,
    backgroundColor: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    border: "2px solid transparent",
    borderRadius: 8,
    backgroundClip: "content-box",
    backgroundColor: "#c1c1c1",
  },
  "::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#a8a8a8",
  },
  "h1, h2, h3, h4, h5, h6": {
    margin: 0,
  },
};

const Bootstrap = () => {
  const [, contextHolder] = notification.useNotification();

  const system = useSystem();
  const { setValue: setSystem } = useStore<System>("system");

  useEffect(() => {
    setSystem(system);
  }, [system, setSystem]);

  const pods = usePods();
  const { setValue: setPods } = useStore<Pods>("pods");

  useEffect(() => {
    setPods(pods);
  }, [pods, setPods]);

  return (
    <>
      {contextHolder}
      <Global styles={styles} />
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard?/*" element={<Home />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Bootstrap;
