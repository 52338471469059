import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Layout, Flex, Space, Breadcrumb, Button } from "antd";
import PodView from "views/home/pod";
import PhaseView from "views/home/phase";
import MilestoneView from "views/home/milestone";
import TaskView from "views/home/task";
import { useStore } from "hooks/store";
import { usePathKeys } from "hooks/dashboard";
import {
  type Pods,
  type Pod,
  type Phase,
  type Milestone,
  type Task,
  type Status,
  StatusColors,
} from "models/pods";
import { entriesURL } from "services/contentful";
import styled from "@emotion/styled";
import {
  MdHome,
  MdOutlineSupervisedUserCircle,
  MdOutlineCalendarMonth,
  MdOutlineDirections,
} from "react-icons/md";
import { PiCheckSquareBold } from "react-icons/pi";
import { MdEdit } from "react-icons/md";

const Root = styled(Layout.Content)({
  padding: 32,
});

const FullWidthSpace = styled(Space)({
  width: "100%",
});

const Title = styled.h1({
  display: "flex",
  alignItems: "center",
  gap: 8,
  "& > :last-child": {
    display: "none",
    cursor: "pointer",
  },
  "&:hover": {
    "& > :last-child": {
      display: "initial",
    },
  },
});

const ItemTag = styled("span", {
  shouldForwardProp: (propName: string) => propName !== "status",
})((props: any) => ({
  padding: "5px 10px",
  borderRadius: 4,
  fontSize: "0.875rem",
  textTransform: "uppercase",
  color: "white",
  backgroundColor: StatusColors[(props.status as Status | undefined) ?? "GRAY"],
}));

const childNames = ["phases", "milestones", "tasks"];

const types = [
  {
    id: "dashboard",
    name: "Dashboard",
    icon: <MdHome size={18} />,
  },
  {
    id: "pod",
    name: "Pod",
    icon: <MdOutlineSupervisedUserCircle size={18} />,
  },
  {
    id: "phase",
    name: "Phase",
    icon: <MdOutlineCalendarMonth size={18} />,
  },
  {
    id: "milestone",
    name: "Milestone",
    icon: <MdOutlineDirections size={18} />,
  },
  {
    id: "task",
    name: "Task",
    icon: <PiCheckSquareBold size={18} />,
  },
];

const riskScores = {
  RED: 4,
  YELLOW: 3,
  BLUE: 2,
  GREEN: 1,
  GRAY: 0,
};

const higher = (a?: Status, b?: Status) =>
  riskScores[a ?? "GRAY"] >= riskScores[b ?? "GRAY"] ? a : b;

const getPodStatus = (pod: Pod) =>
  pod.status ??
  (pod.phases
    ? Array.from(pod.phases.values()).reduce(
        (result, phase) => higher(result, getPhaseStatus(phase)),
        undefined as Status | undefined
      )
    : undefined);

const getPhaseStatus = (phase: Phase) =>
  phase.status ??
  (phase.milestones
    ? Array.from(phase.milestones.values()).reduce(
        (result, milestone) => higher(result, getMilestoneStatus(milestone)),
        undefined as Status | undefined
      )
    : undefined);

const getMilestoneStatus = (milestone: Milestone) =>
  milestone.status ??
  (milestone.tasks
    ? Array.from(milestone.tasks.values()).reduce(
        (result, task) => higher(result, getTaskStatus(task)),
        undefined as Status | undefined
      )
    : undefined);

const getTaskStatus = (task: Task) => task.status;

const getStatus = (type: string, item: any) => {
  switch (type) {
    case "pod":
      return getPodStatus(item);
    case "phase":
      return getPhaseStatus(item);
    case "milestone":
      return getMilestoneStatus(item);
    case "task":
      return getTaskStatus(item);
  }
};

const Dashboard = () => {
  const { value: pods } = useStore<Pods>("pods");
  const pathKeys = usePathKeys();

  const pathItems = useMemo(() => {
    if (pods && pathKeys) {
      const result = [
        {
          type: types[0].id,
          name: "Home",
          icon: types[0].icon,
          url: "/",
          data: undefined as any,
        },
      ];

      let url = "/dashboard";
      let items: Map<string, any> = pods;
      for (let index = 0; index < pathKeys.length; index++) {
        const item = items.get(pathKeys[index]);
        url += `/${item.id}`;
        result.push({
          type: types[index + 1].id,
          name: item.name,
          icon: types[index + 1].icon,
          url,
          data: item,
        });

        items = item[childNames[index]];
        if (!items) {
          break;
        }
      }

      return result;
    }
  }, [pods, pathKeys]);

  const breadcrumbData = useMemo(
    () =>
      pathItems?.map((item, index) => ({
        title: (
          <Flex align="center" gap={8}>
            {item.icon}
            {index < pathItems.length - 1 ? (
              <Link to={item.url}>{item.name}</Link>
            ) : (
              item.name
            )}
          </Flex>
        ),
      })),
    [pathItems]
  );

  if (pathKeys && pathKeys.length > 0 && pathItems && pathItems.length > 0) {
    const key = pathKeys[pathKeys.length - 1];
    const item = pathItems[pathItems.length - 1];
    const type = types[pathKeys.length];
    const status = getStatus(type.id, item.data);
    return (
      <Root>
        <FullWidthSpace direction="vertical" size="large">
          <FullWidthSpace direction="vertical" size="middle">
            <Breadcrumb items={breadcrumbData} separator=">" />
            <Title>
              <ItemTag status={status}>{type.name}</ItemTag>
              {item.name}
              <Button
                type="text"
                size="middle"
                icon={<MdEdit size={22} />}
                href={`${entriesURL}/${key}`}
                target="_blank"
              />
            </Title>
            {item.data?.description && (
              <div>
                {(item.data.description as string)
                  .split(/\r?\n/)
                  .map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
              </div>
            )}
          </FullWidthSpace>
          {item.type === "pod" ? (
            <PodView />
          ) : item.type === "phase" ? (
            <PhaseView />
          ) : item.type === "milestone" ? (
            <MilestoneView />
          ) : item.type === "task" ? (
            <TaskView />
          ) : null}
        </FullWidthSpace>
      </Root>
    );
  }

  return null;
};

export default Dashboard;
