import type { Environment } from "models/system";
import styled from "@emotion/styled";
import { green } from "@ant-design/colors";

const backgroundColors = {
  dev: green.primary,
  prod: "transparent",
};

type EnvironmentSashProps = {
  environment: Environment;
};

const Root = styled.div((props: EnvironmentSashProps) => ({
  position: "absolute",
  left: -20,
  top: 4,
  padding: "2px 20px",
  lineHeight: 1,
  fontSize: 10,
  fontWeight: "bold",
  color: "#f0f0f0",
  backgroundColor: backgroundColors[props.environment],
  textTransform: "uppercase",
  transform: "rotate(-45deg)",
}));

const EnvironmentSash = (props: Partial<EnvironmentSashProps>) =>
  props.environment ? (
    <Root environment={props.environment}>{props.environment}</Root>
  ) : null;

export default EnvironmentSash;
