import { useState } from "react";
import { Flex, Row, Col, Space, Table, type TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import { useStore } from "hooks/store";
import { usePathKeys } from "hooks/dashboard";
import { Pods, Task, Member, StatusColors } from "models/pods";
import { countryCodes } from "models/flags";
import ReactCountryFlag from "react-country-flag";
import { entriesURL } from "services/contentful";
import styled from "@emotion/styled";
import { MdEdit } from "react-icons/md";
import { BsFillExclamationDiamondFill } from "react-icons/bs";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const FullWidthSpace = styled(Space)({
  width: "100%",
});

const IconLink = styled.a({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "inherit",
});

const RiskTable = styled(Table<Task>)({
  "& .ant-table-cell": {
    lineHeight: 1,
  },
});

const RiskCheckBox = styled("span", {
  shouldForwardProp: (propName: string) => propName !== "type",
})((props: any) => ({
  display: "inline-flex",
  color:
    props.type === "high"
      ? StatusColors.RED
      : props.type === "medium"
      ? StatusColors.YELLOW
      : props.type === "low"
      ? StatusColors.BLUE
      : props.type === "no"
      ? StatusColors.GREEN
      : StatusColors.GRAY,
}));

const MemberTable = styled(Table<Member>)({
  "& .ant-table-cell": {
    lineHeight: 1,
  },
});

const CountryFlag = styled(ReactCountryFlag)({
  fontSize: "1.25rem",
});

const riskColumns: ColumnsType<Task> = [
  {
    key: "name",
    title: "Task",
    dataIndex: "name",
    render: (_text: string, task: Task) => (
      <Space>
        <BsFillExclamationDiamondFill
          size={20}
          color={StatusColors[task.status ?? "GRAY"]}
        />
        <span>{task.name}</span>
      </Space>
    ),
  },
  {
    key: "edit",
    title: (
      <Flex justify="center" align="center">
        <MdEdit size={16} />
      </Flex>
    ),
    dataIndex: "id",
    width: "40px",
    render: (id: string) => (
      <Flex justify="center" align="center">
        <IconLink href={`${entriesURL}/${id}`} target="_blank">
          <MdEdit size={16} />
        </IconLink>
      </Flex>
    ),
  },
];

const memberColumns: ColumnsType<Member> = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    render: (_text: string, member: Member) => (
      <Space>
        {member.nationality && (
          <CountryFlag countryCode={countryCodes[member.nationality]} svg />
        )}
        <span>{member.name}</span>
      </Space>
    ),
  },
  {
    key: "role",
    title: "Role",
    dataIndex: "role",
  },
  {
    key: "email",
    title: "Email",
    dataIndex: "email",
    render: (text: string) => <a href={`mailto:${text}`}>{text}</a>,
  },
  {
    key: "edit",
    title: (
      <Flex justify="center" align="center">
        <MdEdit size={16} />
      </Flex>
    ),
    dataIndex: "id",
    width: "40px",
    render: (id: string) => (
      <Flex justify="center" align="center">
        <IconLink href={`${entriesURL}/${id}`} target="_blank">
          <MdEdit size={16} />
        </IconLink>
      </Flex>
    ),
  },
];

const pagination: TablePaginationConfig = {
  hideOnSinglePage: true,
  defaultPageSize: 10,
};

const Pod = () => {
  const { value: pods } = useStore<Pods>("pods");
  const pathKeys = usePathKeys();

  const [highRisk, setHighRisk] = useState(true);
  const [mediumRisk, setMediumRisk] = useState(false);
  const [lowRisk, setLowRisk] = useState(false);
  const [noRisk, setNoRisk] = useState(false);
  const [unknownRisk, setUnknownRisk] = useState(false);

  const toggleHighRisk = () => setHighRisk((risk) => !risk);
  const toggleMediumRisk = () => setMediumRisk((risk) => !risk);
  const toggleLowRisk = () => setLowRisk((risk) => !risk);
  const toggleNoRisk = () => setNoRisk((risk) => !risk);
  const toggleUnknownRisk = () => setUnknownRisk((risk) => !risk);

  if (pods && pathKeys && pathKeys.length > 0) {
    const pod = pods.get(pathKeys[0]);
    if (pod) {
      const members = pod.members
        ? Array.from(pod.members.entries()).map(([key, member]) => ({
            ...member,
            id: key,
          }))
        : undefined;

      const risks: Task[] = [];
      pod.phases?.forEach((phase) => {
        phase.milestones?.forEach((milestone) => {
          milestone.tasks?.forEach((task, taskKey) => {
            if (
              (highRisk && task.status === "RED") ||
              (mediumRisk && task.status === "YELLOW") ||
              (lowRisk && task.status === "BLUE") ||
              (noRisk && task.status === "GREEN") ||
              (unknownRisk && task.status === undefined)
            ) {
              risks.push({ ...task, id: taskKey });
            }
          });
        });
      });

      return (
        <Row gutter={16}>
          <Col span={12}>
            <FullWidthSpace direction="vertical" size="middle">
              <Flex justify="space-between" align="center">
                <h2>Risks</h2>
                <Flex>
                  <RiskCheckBox type="high" onClick={toggleHighRisk}>
                    {highRisk ? (
                      <MdCheckBox size={24} />
                    ) : (
                      <MdCheckBoxOutlineBlank size={24} />
                    )}
                  </RiskCheckBox>
                  <RiskCheckBox type="medium" onClick={toggleMediumRisk}>
                    {mediumRisk ? (
                      <MdCheckBox size={24} />
                    ) : (
                      <MdCheckBoxOutlineBlank size={24} />
                    )}
                  </RiskCheckBox>
                  <RiskCheckBox type="low" onClick={toggleLowRisk}>
                    {lowRisk ? (
                      <MdCheckBox size={24} />
                    ) : (
                      <MdCheckBoxOutlineBlank size={24} />
                    )}
                  </RiskCheckBox>
                  <RiskCheckBox type="no" onClick={toggleNoRisk}>
                    {noRisk ? (
                      <MdCheckBox size={24} />
                    ) : (
                      <MdCheckBoxOutlineBlank size={24} />
                    )}
                  </RiskCheckBox>
                  <RiskCheckBox onClick={toggleUnknownRisk}>
                    {unknownRisk ? (
                      <MdCheckBox size={24} />
                    ) : (
                      <MdCheckBoxOutlineBlank size={24} />
                    )}
                  </RiskCheckBox>
                </Flex>
              </Flex>
              <RiskTable
                rowKey="id"
                columns={riskColumns}
                dataSource={risks}
                pagination={pagination}
                size="small"
                bordered
              />
            </FullWidthSpace>
          </Col>
          <Col span={12}>
            <FullWidthSpace direction="vertical" size="middle">
              <h2>Pod Members</h2>
              <MemberTable
                rowKey="id"
                columns={memberColumns}
                dataSource={members}
                pagination={pagination}
                size="small"
                bordered
              />
            </FullWidthSpace>
          </Col>
        </Row>
      );
    }
  }

  return null;
};

export default Pod;
