import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import EnvironmentSash from "components/environment-sash";
import { useStore } from "hooks/store";
import type { System } from "models/system";
import styled from "@emotion/styled";
import { ReactComponent as LogoImage } from "assets/images/logo.svg";
import { ReactComponent as BMOImage } from "assets/images/bmo.svg";

const Root = styled(Layout.Header)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Masthead = styled(Link)({
  display: "inline-flex",
  alignItems: "center",
  gap: 16,
});

const ProductLogo = styled(LogoImage)({
  fontSize: "2.125rem",
});

const ProductName = styled.span({
  fontSize: "1rem",
  fontWeight: 500,
  textTransform: "uppercase",
  color: "#f0f0f0",
  "&:hover": {
    color: "#f0f0f0",
  },
});

const Separator = styled.span({
  width: 2,
  height: 18,
  backgroundColor: "#d9d9d9",
});

const ClientLogo = styled(BMOImage)({
  fontSize: "1.5rem",
});

const ClientName = styled.span({
  fontSize: "1rem",
  fontWeight: 500,
  color: "#f0f0f0",
  "&:hover": {
    color: "#f0f0f0",
  },
});

type HeaderProps = {
  children?: React.ReactNode | React.ReactNode[];
};

const Header = ({ children }: HeaderProps) => {
  const { value: system } = useStore<System>("system");

  return (
    <Root>
      <Masthead to="/">
        <ProductLogo />
        <ProductName>Synergy</ProductName>
        <Separator />
        <ClientLogo />
        <ClientName>BMO</ClientName>
      </Masthead>
      {children}
      <EnvironmentSash environment={system?.environment} />
    </Root>
  );
};

export default Header;
