const call = async (
  url: string,
  method: string,
  headers?: HeadersInit,
  body?: BodyInit
) => {
  try {
    headers = {
      Accept: "application/json",
      ...headers,
    };
    if (method !== "GET") {
      headers = {
        "Content-Type": "application/json",
        ...headers,
      };
    }

    const options: RequestInit = {
      method,
      headers,
      credentials: "include",
      body,
    };

    const response = await fetch(url, options);
    if (response.status === 204) {
      return {
        status: "success",
      };
    } else {
      return await response.json();
    }
  } catch (e: any) {
    return {
      status: "error",
      result: {
        message: e.toString(),
      },
    };
  }
};

export const getSystem = () => {
  const url = `${process.env.REACT_APP_SYNERGY_API_URL}/api`;
  return call(url, "GET");
};
