import { useEffect, useRef, useCallback } from "react";
import ReactFlow, {
  Controls,
  ControlButton,
  Background,
  BackgroundVariant,
  useReactFlow,
  useNodesState,
  useEdgesState,
  type Node,
  type Edge,
  type OnConnectStartParams,
  type Connection,
  addEdge,
} from "reactflow";
import dagre from "dagre";
import { useStore } from "hooks/store";
import { usePathKeys } from "hooks/dashboard";
import { Pods } from "models/pods";
import { saveTaskFlow } from "services/contentful";
import { GrApps } from "react-icons/gr";
import { RxReload } from "react-icons/rx";
import { FaSave } from "react-icons/fa";

const applyAutoLayout = (nodes: any[], edges: any[]) => {
  const graph = new dagre.graphlib.Graph();
  graph.setGraph({});
  graph.setDefaultEdgeLabel(() => ({}));

  nodes.forEach((node) => {
    graph.setNode(node.id, {
      width: node.width,
      height: node.height,
    });
  });

  edges.forEach((edge) => {
    graph.setEdge(edge.source, edge.target);
  });

  graph.nodes().forEach((node) => {
    const element = nodes.find((element) => element.id === node);
    if (element) {
      element.position = graph.node(node);
    }
  });

  dagre.layout(graph);
};

const getInitialElements = (pods?: Pods, pathKeys?: string[]) => {
  let nodes: Node<any, string | undefined>[] = [];
  let edges: Edge<any>[] = [];

  if (pods && pathKeys && pathKeys.length > 2) {
    const pod = pods.get(pathKeys[0]);
    if (pod?.phases) {
      const phase = pod.phases.get(pathKeys[1]);
      if (phase?.milestones) {
        const milestone = phase.milestones.get(pathKeys[2]);
        if (milestone?.flow) {
          nodes = milestone.flow.nodes;
          edges = milestone.flow.edges;
        }
        if (milestone?.tasks) {
          Array.from(milestone.tasks.entries()).forEach(
            ([key, task], index) => {
              if (!nodes.some((node) => node.id === key)) {
                nodes.push({
                  id: key,
                  data: {
                    label: task.name,
                  },
                  position: {
                    x: 40,
                    y: index * 100 + 40,
                  },
                });
              }
            }
          );
        }
      }
    }
  }

  return { nodes, edges };
};

const Flow = () => {
  const { value: pods } = useStore<Pods>("pods");
  const pathKeys = usePathKeys();

  const [nodes, setNodes, onNodesChange] = useNodesState<any>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any>([]);

  const initialize = useCallback(() => {
    const { nodes, edges } = getInitialElements(pods, pathKeys);
    setNodes(nodes);
    setEdges(edges);
  }, [pods, pathKeys, setNodes, setEdges]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const startNodeIdRef = useRef<string | null>(null);

  const connectStart = (
    e: React.MouseEvent | React.TouchEvent,
    params: OnConnectStartParams
  ) => {
    e.preventDefault();

    startNodeIdRef.current = params.nodeId;
  };

  const connectEnd = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();

    const startNodeId = startNodeIdRef.current;
    if (startNodeId) {
      if (e.target) {
        const target: any = e.target;
        const targetIsPane = target.classList.contains("react-flow__pane");
        if (targetIsPane) {
          const endNodeId = `#${Date.now()}`;
          let offsetX, offsetY;
          if ("offsetX" in e && "offsetY" in e) {
            offsetX = e.offsetX;
            offsetY = e.offsetY;
          } else {
            const { x, y, width, height } = target.getBoundingClientRect();
            offsetX = ((e.touches[0].clientX - x) / width) * target.offsetWidth;
            offsetY =
              ((e.touches[0].clientY - y) / height) * target.offsetHeight;
          }
          const newNode = {
            id: endNodeId,
            data: {
              label: "New Node",
            },
            position: {
              x: offsetX,
              y: offsetY,
            },
          };
          setNodes([...nodes, newNode]);

          const newEdge = {
            id: `${startNodeId}-${endNodeId}`,
            source: startNodeId,
            target: endNodeId,
          };
          setEdges([...edges, newEdge]);
        }
      }

      startNodeIdRef.current = null;
    }
  };

  const connect = (connection: Connection) =>
    setEdges(addEdge(connection, edges));

  const { getNodes, getEdges } = useReactFlow();

  const autoLayout = () => {
    const nodes = getNodes();
    const edges = getEdges();
    applyAutoLayout(nodes, edges);
    setNodes(nodes);
  };

  const save = () => {
    if (pathKeys && pathKeys.length > 2) {
      saveTaskFlow(pathKeys[2], {
        nodes: getNodes(),
        edges: getEdges(),
      });
    }
  };

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnectStart={connectStart}
      onConnectEnd={connectEnd}
      onConnect={connect}
    >
      <Controls position="top-right">
        <ControlButton title="Auto Layout" onClick={autoLayout}>
          <GrApps />
        </ControlButton>
        <ControlButton title="Reset" onClick={initialize}>
          <RxReload />
        </ControlButton>
        <ControlButton title="Save" onClick={save}>
          <FaSave />
        </ControlButton>
      </Controls>
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
    </ReactFlow>
  );
};

export default Flow;
