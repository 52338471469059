import {
  useMemo,
  useCallback,
  useContext,
  useReducer,
  createContext,
  type Dispatch,
} from "react";

type StoreStateProps = Record<string, any>;

type StoreDispatchProps = {
  type: "update" | "delete";
  name: string;
  value?: any;
};

type StoreContextProps = {
  state: StoreStateProps;
  dispatch: Dispatch<StoreDispatchProps>;
};

const initialState = {};
const initialDispatch = () => undefined;

const StoreContext = createContext<StoreContextProps>({
  state: initialState,
  dispatch: initialDispatch,
});

const reducer = (state: StoreStateProps, action: StoreDispatchProps) => {
  switch (action.type) {
    case "update":
      state[action.name] = action.value;
      return { ...state };
    case "delete":
      delete state[action.name];
      return { ...state };
    default:
      return state;
  }
};

type StoreProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const StoreProvider = (props: StoreProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StoreContext.Provider>
  );
};

export const useStore = <T,>(name: string) => {
  const { state, dispatch } = useContext(StoreContext);
  const value = useMemo(() => state[name] as T | undefined, [state, name]);
  const setValue = useCallback(
    (value?: T) => {
      if (value !== undefined) {
        dispatch({ type: "update", name, value });
      } else {
        dispatch({ type: "delete", name });
      }
    },
    [name, dispatch]
  );
  return { value, setValue };
};
