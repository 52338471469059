import { useState, useEffect } from "react";
import type { System } from "models/system";
import { getSystem } from "services/api";

export const useSystem = () => {
  const [system, setSystem] = useState<System>();

  useEffect(() => {
    (async () => {
      const { status, result } = await getSystem();
      if (status === "success") {
        const newSystem = {
          environment: result.environment,
        };
        setSystem(newSystem);
      }
    })();
  }, []);

  return system;
};
